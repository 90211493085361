import './RadioGroup.component.scss';

interface RadioGroupProps {
	align: 'vertical' | 'horizontal';
	className: string;
	items: Array<itemsProps>;
	name: string;
	setter: (value: string) => void;
	value: string | undefined;
}

interface itemsProps {
	label: string;
	value: string;
}

const RadioGroup: React.FC<RadioGroupProps> = ({ align, className, items, name, value, setter }): JSX.Element => {
	return (
		<div className={`radio-group__container ${className} ${align === 'horizontal' ? 'horizontal' : ''}`}>
			{items.map((element: itemsProps, index: number) => {
				return (
					<div className="radio-group__item">
						<input
							type="radio"
							id={`${name}-${index}`}
							name={name}
							value={element.value}
							checked={value === element.value ? true : false}
							onChange={(e) => {
								setter(e.target.value);
							}}
						/>
						<label htmlFor={`${name}-${index}`}>{element.label}</label>
					</div>
				);
			})}
		</div>
	);
};

export default RadioGroup;
